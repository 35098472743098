<template>
	<w-section :title="value.provider.name + (value.label ? ` (${value.label})` : '') + ' :'" no-margin>
		<template v-slot:header>
			<w-layout v-if="$vuetify.breakpoint.mdAndUp" justify-end row>
				<w-flex mr-2 shrink>
					<w-divider vertical />
				</w-flex>
				<w-btn :disabled="authenticatorLoader || cancelLoader" :loading="authenticatorLoader" flat icon="preview" mini @click="openPendingConnectionWebview()">
					{{ $t('bill-scrapper.automations.open_webview_pending_connection') }}
				</w-btn>
				<w-btn-delete
					:disabled="cancelLoader"
					:loading="cancelLoader"
					:text="$t('bill-scrapper.automations.cancel_pending_connection')"
					mini
					@click="cancelPendingConnection()"
				/>
			</w-layout>

			<w-menu v-else :close-on-click="false" bottom>
				<template v-slot:activator="{ on }">
					<w-btn flat icon="more_vert" mini v-on="on" />
				</template>
				<w-list>
					<w-list-tile :disabled="cancelLoader || authenticatorLoader" avatar @click="openPendingConnectionWebview()">
						<w-list-tile-avatar>
							<v-progress-circular v-if="authenticatorLoader" color="primary" indeterminate />
							<w-icon color="primary">preview</w-icon>
						</w-list-tile-avatar>
						<w-list-tile-content>
							<w-list-tile-title v-t="'bill-scrapper.automations.open_webview_pending_connection'" />
						</w-list-tile-content>
					</w-list-tile>
					<w-list-tile :disabled="cancelLoader || authenticatorLoader" avatar @click="cancelPendingConnection()">
						<w-list-tile-avatar>
							<v-progress-circular v-if="cancelLoader" color="primary" indeterminate />
							<w-icon color="error">delete</w-icon>
						</w-list-tile-avatar>
						<w-list-tile-content>
							<w-list-tile-title v-t="'bill-scrapper.automations.cancel_pending_connection'" />
						</w-list-tile-content>
					</w-list-tile>
				</w-list>
			</w-menu>
		</template>
	</w-section>
</template>

<script>
import BillScrapperModuleGuard from '@/mixins/ModulesGuards/BillScrapper/BillScrapperModuleGuard'

export default {
	name: 'PendingConnectionItem',
	mixins: [BillScrapperModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			authenticatorLoader: false,
			cancelLoader: false,
			copiedValue: {}
		}
	},
	watch: {
		value: {
			deep: true,
			handler: function (val) {
				if (val) {
					this.copiedValue = { ...val }
				}
			},
			immediate: true
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{
					event: this.events.SHOW_PENDING_CONNECTION_WEBVIEW,
					action: this.openingPendingConnectionWebview
				},
				{
					event: this.events.CLOSE_PENDING_CONNECTION_WEBVIEW,
					action: this.closePendingConnectionWebview
				},
				{
					event: this.events.UPDATE_PENDING_CONNECTION,
					action: this.updatePendingConnection
				},
				{
					event: this.events.CANCEL_PENDING_CONNECTION_WEBVIEW,
					action: this.cancelPendingConnection
				}
			]
		},
		cancelPendingConnection: function (connection = null) {
			if (connection !== null && this.value.id !== connection.id) {
				return
			}
			this.cancelLoader = true
			this.service.cancelPendingConnection(this.value.id)
		},
		closePendingConnectionWebview: function (connection) {
			if (connection !== null && this.value.id !== connection.id) {
				return
			}
			this.authenticatorLoader = true
			this.eventBus.emit(this.events.UPDATE_PENDING_CONNECTION, this.value)
		},
		updatePendingConnection: async function (connection) {
			if (connection !== null && this.value.id !== connection.id) {
				return
			}
			await this.service.updatePendingConnection(this.value.id, true)
			this.authenticatorLoader = false
		},
		openPendingConnectionWebview: function () {
			this.eventBus.emit(this.events.SHOW_PENDING_CONNECTION_WEBVIEW, this.copiedValue)
		},
		openingPendingConnectionWebview: function (connection) {
			if (connection !== null && this.value.id !== connection.id) {
				return
			}

			this.authenticatorLoader = true
		}
	}
}
</script>
